<template>
    <div class="vertical-tab row my-lg-5">
        <div class="vertical-tab__links p-0 m-3">
            <div :class="[selected === 'aciklama' ? 'vertical-tab__link--selected' : '']"
                 class="vertical-tab__link"
                 v-on:click="openContent('aciklama')">
                <span class="tag p-3" :class="[selected === 'aciklama' ? 'tag--in' : 'tag--tab']">
                    <i class="fas fa-chevron-right"></i> Açıklama</span>
            </div>
            <div :class="[selected === 'bakim' ? 'vertical-tab__link--selected' : '']"
                 class="vertical-tab__link"
                 v-if="checkBakim()"
                 v-on:click="openContent('bakim')">
                <span class="tag p-3" :class="[selected === 'bakim' ? 'tag--in' : 'tag--tab']">
                    <i class="fas fa-chevron-right"></i> BAKIM BİLGİLERİ</span>
            </div>
            <div :class="[selected === 'bitki-gereksinimleri' ? 'vertical-tab__link--selected' : '']"
                 class="vertical-tab__link"
                 v-if="checkBakimIhtiyaci()"
                 v-on:click="openContent('bitki-gereksinimleri')">
                <span class="tag p-3" :class="[selected === 'bitki-gereksinimleri' ? 'tag--in' : 'tag--tab']">
                    <i class="fas fa-chevron-right"></i> BİTKİ GEREKSİNİMLERİ</span>
            </div>
            <div :class="[selected === 'yorumlar' ? 'vertical-tab__link--selected' : '']"
                 class="vertical-tab__link"
                 v-if="items.yorumlar"
                 v-on:click="openContent('yorumlar')">
                <span class="tag p-3" :class="[selected === 'yorumlar' ? 'tag--in' : 'tag--tab']">
                    <i class="fas fa-chevron-right"></i> Yorumlar</span>
            </div>
        </div>
        <div :class="[selected === 'aciklama' ? 'vertical-tab__content--selected': '']"
             class="vertical-tab__content m-3 pl-5 pr-5 pb-5">
            <h2 class="mb-3"> {{ items.urun.ad }} Hakkında </h2>
            <ul class="product-info-list">
                <li class="product-info-item">
                    <span><b>Ürün Boyutu:</b> {{ items.ozellik.urunboyutu }}</span>
                </li>
                <li class="product-info-item">
                    <span><b>Kullanılan Malzemeler:</b> {{ items.ozellik.malzemeler }}</span>
                </li>
            </ul>

            <div class="product-description" v-html="items.urun.aciklama">
            </div>

            <div class="payment-summary my-5">
                <img src="/img/taksit-ozet.png" alt="Taksit Özet">
            </div>
        </div>
        <div :class="[selected === 'bakim' ? 'vertical-tab__content--selected' : '']"
             class="vertical-tab__content m-3 p-5"
             v-if="checkBakim()">
            <span v-html="items.bakim"></span>
        </div>
        <div :class="[selected === 'bitki-gereksinimleri' ? 'vertical-tab__content--selected' : '']"
             class="vertical-tab__content m-3 p-5"
             v-if="checkBakimIhtiyaci()">
            <div class="row properties__container justify-content-center m-0">
                <div class="properties__header">
                    <h3>ÖZELLİKLER</h3>
                </div>
                <table class="properties my-5">
                    <tbody class="properties__body">
                    <tr class="properties__row"
                        v-for="(item, key) in items.bitkibakim"
                        :key="key"
                    >
                        <td class="properties__icon">
                            <img :src="'https://www.cicekdiyari.com/' + item['resim']">
                        </td>
                        <td class="properties__name p-2">
                            {{ item['baslik'] }}
                        </td>
                        <td class="properties__data p-2">
                            {{ item['icerik'] }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div :class="[selected === 'yorumlar' ? 'vertical-tab__content--selected' : '']"
             class="vertical-tab__content m-3 p-5"
             v-if="items.yorumlar">
            <div class="comments" v-if="items.yorumlar">
                <span class="comments__title">
                    <h3>SİTEMİZE GELEN YORUMLAR ({{ items.yorumlar.length }})</h3>
                </span>
                <div class="comment my-5 p-4" v-for="(yorum, index) in items.yorumlar" :key="index">
                    <span class="comment__title p-2"> <h4>{{ yorum.baslik }}</h4></span>
                    <span class="comment__eval p-2">
                    <i class="fas fa-star star-checked" v-for="i in 5"></i>
                </span>

                    <p class="comment__body p-2"> {{ yorum.yorum }} </p>

                    <div>
                        <label class="comment__commenter-label" for="commenter">Gönderen:</label>
                        <span class="comment__commenter p-2" id="commenter"> {{ yorum.adsoyad }} </span>
                    </div>

                </div>
                <a class="my-5" href="/cicek-diyari-yorumlar">Tüm yorumlar için tıklayınız.</a>
            </div>
            <div class="d-flex flex-column add-comment">
                <h3>YORUM EKLE</h3>
                <form class="d-flex flex-column add-comment-form">
                    <input class="my-3 p-3" type="text" name="adsoyad" placeholder="Adınız Soyadınız">
                    <input class="my-3 p-3" type="text" name="eposta" placeholder="E-posta Adresiniz">
                    <input class="my-3 p-3" type="text" name="baslik" placeholder="Yorum Başlığı">
                    <textarea class="my-3 p-3" name="yorum" cols="30" rows="10" placeholder="Yorumunuz"></textarea>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            verticalTabItems: String,
        },
        data() {
            return {
                items: JSON.parse(this.verticalTabItems),
                selected: 'aciklama'
            }
        },
        methods: {
            checkBakim() {
                return this.items.bakim && !this.items.bakim.toLocaleLowerCase().includes('bakım bilgilerini giriniz')
            },
            openContent(item) {
                this.selected = item;
            },
            checkBakimIhtiyaci() {
                return Boolean(this.items.bitkibakim)
            }
        },
    }
</script>
