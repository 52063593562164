<template>
    <form-input-layout :id="id" :label="label" :rules="rules">
        <template v-slot:input>
            <input
                :id="id"
                :name="name"
                :type="type"
                :placeholder="placeholder"
                :value="value"
                :checked="value"
                @input="
                    $emit(
                        'input',
                        type === 'checkbox'
                            ? $event.target.checked
                            : $event.target.value
                    )
                "
            />
        </template>
    </form-input-layout>
</template>
<script>
import FormInputLayout from "./FormInputLayout";

export default {
    props: {
        id: String,
        name: String,
        value: [String, Boolean],
        label: String,
        type: String,
        placeholder: {
            type: String,
            default: "",
        },
        rules: String
    },
    components: {
        FormInputLayout
    },
};
</script>
