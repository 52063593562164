<template>
    <div class="order-select-order">
        <select v-model="selectedOrder" :disabled="this.disabled && this.ayniadresikullan">
            <option v-for="(siparis, index) in Object.values(siparisler)" :key="index" :value="siparis.sepetici_id">
                {{ index + 1 }}: {{ siparis.urun.ad }}
            </option>
        </select>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "OrderSelect",
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('sepet', [
            'siparisler',
            'secilmisSiparis',
            'ayniadresikullan'
        ]),
        selectedOrder: {
            get() {
                return this.secilmisSiparis
            },
            set(value) {
                this.setSecilmisSiparis(value)
            }
        }
    },
    methods: {
        ...mapActions('sepet', [
            'setSecilmisSiparis'
        ])
    }
}
</script>
