<template>
  <div>
    <div class="row">
      <div class="col-12">
        <form-input
          id="faturaUnvan"
          name="faturaUnvan"
          type="text"
          label="Fatura Unvan"
          v-model="faturaUnvan"
          :required="true"
          placeholder
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <form-input
          id="vergiDairesi"
          name="vergiDairesi"
          type="text"
          label="Vergi Dairesi"
          v-model="vergiDairesi"
          :required="true"
          placeholder
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <form-input
          id="vergiNo"
          name="vergiNo"
          type="text"
          label="Vergi No"
          v-model="vergiNo"
          :required="true"
          placeholder
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <form-textarea
          id="faturaAdresi"
          name="faturaAdresi"
          label="Fatura Adresi"
          :rows="4"
          v-model="faturaAdresi"
          :required="true"
          placeholder
        />
      </div>
    </div>
  </div>
</template>
<script>
import { FormInput, FormTextarea } from "../../FormItems";

export default {
  components: {
    FormInput,
    FormTextarea
  },
  data() {
      return {
          faturaUnvan: "",
          vergiDairesi: "",
          vergiNo: "",
          faturaAdresi: "",
      }
  }
};
</script>
