import Vue from 'vue'
import Vuex from 'vuex'

import ui from "./modules/ui";
import sepet from "./modules/sepet";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        ui,
        sepet
    },
    plugins: [
        createPersistedState()
    ],
})


