<template>
  <div>
    <div class="row">
      <div class="col-12">
        <form-input
          id="faturaAdsoyad"
          name="faturaAdsoyad"
          type="text"
          label="Fatura Adı ve Soyadı"
          v-model="faturaAdsoyad"
          :required="true"
          placeholder
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <form-input
          id="faturaTCNo"
          name="faturaTCNo"
          type="text"
          label="Fatura Adı ve Soyadı"
          v-model="faturaTCNo"
          :required="true"
          placeholder
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <form-input
          id="faturaIl"
          name="faturaIl"
          type="text"
          label="Fatura İl"
          v-model="faturaIl"
          :required="true"
          placeholder
        />
      </div>
      <div class="col-lg-6">
        <form-input
          id="faturaIlce"
          name="faturaIlce"
          type="text"
          label="Fatura İlçe"
          v-model="faturaIlce"
          :required="true"
          placeholder
        />
      </div>
    </div>
  </div>
</template>
<script>
import { FormInput } from "../../FormItems";

export default {
  components: {
    FormInput
  },
  data() {
    return {
      faturaAdsoyad: "",
      faturaTCNo: "",
      faturaIl: "",
      faturaIlce: ""
    };
  }
};
</script>
