<template>
    <button
        class="btn btn--color-white btn--bg-pink my-2 ml-auto mr-auto"
        :class="{'btn--disabled': isDisabled}"
        @click="handleClick"
        :disabled="isDisabled"
    >
        Devam Et
    </button>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "NextOrderPageButton",
    props: {
        nextPath: String,
        nextPageNumber: Number,
    },
    methods: {
        ...mapActions('ui', [
            'selectOrderPage'
        ]),
        handleClick() {
            window.location = this.nextPath
            this.selectOrderPage(this.nextPageNumber)
        }
    },
    computed: {
        ...mapGetters('information', [
            'siparisOlusturuldu'
        ]),
        isDisabled() {
            if (this.nextPageNumber === 2) {
                if (this.siparisOlusturuldu) {
                    return false
                }
                return true
            }
            return false
        }
    }
}
</script>
