<template>
    <div class="carousel d-flex flex-row flex-nowrap col-md-7 p-0">
        <div class="carousel__thumbnail m-4">
            <div v-for="(item, index) in items" :class="thumbnailClass(index)" ref="thumbnailItem"
                 v-on:click="handleThumbnailClick(index)">
                <img :src="item">
            </div>
        </div>
        <div class="carousel__content m-4">
            <div class="carousel__items" ref="items">
                <div class="carousel__item" v-for="(img, index) in items" :key="index"
                     v-show="index===selectedItem">
                    <a :href="img" class="fancybox" rel="group" ><img :src="img" alt="deneme"></a>
                </div>
            </div>

            <div class="carousel__actions">
                <div class="carousel__prev p-2" @click="prev"
                     :class="[selectedItem === 0 ? 'carousel__prev--disabled' : 0]">
                    <i class="fas fa-chevron-left fa-2x"></i>
                </div>
                <div class="carousel__next p-2" @click="next"
                     :class="[selectedItem === items.length - 1 ? 'carousel__prev--disabled' : 0]">
                    <i class="fas fa-chevron-right fa-2x"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                selectedItem: 0,
                items: JSON.parse(this.itemsRaw)
            }
        },
        props: ['itemsRaw'],
        methods: {
            slideClass(el) {
                if (this.selectedItem > 0) {
                    return ' ' + 'carousel__' + el + '--slide-' + this.selectedItem
                } else {
                    return ''
                }
            },

            itemClass() {
                return this.slideClass('item')
            },
            thumbnailClass(index) {
                let classThumbnail = 'carousel__thumbnail__item mb-3'

                if (this.selectedItem === index) {
                    classThumbnail += ' carousel__thumbnail__item--selected'
                }

                classThumbnail += this.slideClass('thumbnail__item')

                return classThumbnail
            },
            prev() {
                if (this.selectedItem > 0) {
                    this.selectedItem--;
                }
            },
            next() {
                if (this.selectedItem < this.items.length - 1) {
                    this.selectedItem++;
                }
            },
            handleThumbnailClick(index) {
                this.selectedItem = index;
            }
        }
    }
</script>
