export function calculateTotalPrice(orders) {
    return orders && Object.values(orders).reduce((acc, order) => order.urun.fiyat + calculateEkstralarPrice(order) + acc, 0.0)
}

export function calculateEkstralarPrice(order) {
    if (order.hasOwnProperty("ekstralar")) {
        return Object.values(order.ekstralar).reduce((acc, ekstra) => acc + ekstra.adet * ekstra.fiyat, 0.0)
    }
    return 0
}
