<template>
    <div class="order-form">
        <ValidationObserver v-slot="{validate}">
            <form action="https://vpostest.qnbfinansbank.com/Gateway/Default.aspx" method="post"
                  @submit="event => validateAndSubmit(validate, event)">
                <input type="hidden" name="_token" id="csrf-token" :value="csrf_token">
                <div class="row">
                    <div v-for="(odeme, index) in odemeTipleri" v-bind:key="index" class="col-3">
                        <div class="order-payment__payment-method">
                            <input
                                :id="odeme.value"
                                type="radio"
                                name="paymentMethod"
                                :value="odeme.value"
                                v-model="odemeTipi"
                            />
                            <label :for="odeme.value">{{ odeme.label }}</label>
                        </div>
                    </div>
                </div>

                <component :is="odemeTipi"/>

                <div v-if="['kredikarti', 'bankakarti'].includes(odemeTipi)">
                    <input type="hidden" name="MbrId" :value="mbrId"/>
                    <input type="hidden" name="MerchantID" :value="merchantId"/>
                    <input type="hidden" name="UserCode" :value="userCode"/>
                    <input type="hidden" name="SecureType" :value="secureType"/>
                    <input type="hidden" name="TxnType" :value="txnType"/>
                    <input type="hidden" name="InstallmentCount" :value="installmentCount"/>
                    <input type="hidden" name="Currency" :value="currency"/>
                    <input type="hidden" name="OkUrl" :value="okUrl"/>
                    <input type="hidden" name="FailUrl" :value="failUrl"/>
                    <input type="hidden" name="OrderId" :value="orderId"/>
                    <input type="hidden" name="PurchAmount" :value="purchAmount"/>
                    <input type="hidden" name="Lang" :value="lang"/>
                    <input type="hidden" name="Rnd" :value="rnd"/>
                    <input type="hidden" name="Hash" :value="hash"/>
                </div>
                <button
                    class="btn btn--color-white btn--bg-pink my-2 ml-auto mr-auto"
                    type="submit"
                >
                    Öde ve Siparişi Bitir
                </button>

            </form>
        </ValidationObserver>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {ValidationObserver} from "vee-validate";
import Kredikarti from "./Kredikarti";
import Bankakarti from "./Bankakarti";
import Taksitliodeme from "./Taksitliodeme";
import Havaleodeme from "./Havaleodeme";

export default {
    name: "OrderPayment",
    props: {
        mbrId: String,
        merchantId: String,
        userCode: String,
        secureType: String,
        txnType: String,
        installmentCount: String,
        currency: String,
        okUrl: String,
        failUrl: String,
        orderId: String,
        purchAmount: String,
        lang: String,
        rnd: String,
        hash: String,
    },

    components: {
        Kredikarti,
        Bankakarti,
        Taksitliodeme,
        Havaleodeme,
        ValidationObserver
    },

    data() {
        return {
            odemeTipleri: [
                {
                    value: "kredikarti",
                    label: "Kredi Kartı ile Ödeme",
                },
                {
                    value: "bankakarti",
                    label: "Banka Kartı ile Ödeme",
                },
                {
                    value: "taksitliodeme",
                    label: "Taksitli Ödeme",
                },
                {
                    value: "havaleodeme",
                    label: "Havale / EFT ile Ödeme",
                }
            ],
            csrf_token: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        };
    },

    methods: {
        ...mapActions('sepet', {
            toggleReceipt: 'toggleReceipt',
            setPaymentMethod: 'setOdemetipi',
        }),

        validateAndSubmit: function (validate, event) {
            event.preventDefault();
            validate().then(validateResult => {
                if (validateResult) {
                    const cardNumber = event.target[6].value;
                    const cardName = event.target[7].value;
                    const cardMonth = event.target[8].value;
                    const cardYear = event.target[9].value;
                    const cardCvv = event.target[10].value;

                    if (
                        cardNumber &&
                        cardName &&
                        cardMonth &&
                        cardYear &&
                        cardCvv
                    ) {
                        console.log(event);
                        event.target.submit();
                    }
                }
            });
        }
    },

    computed: {

        ...mapGetters('sepet', [
            'faturavarmi',
            'odemetipi'
        ]),

        odemeTipi: {
            get() {
                return this.odemetipi;
            },
            set(newValue) {
                this.setPaymentMethod(newValue)
            }
        }
    },
};
</script>
<style lang="scss">
@import "~@/_variables.scss";

.order-form {
    &__input--card {
        width: 25%;
    }
}

.order-payment {
    &__payment-method {
        input[type="radio"] {
            display: none;
        }

        input[type="radio"] ~ label {
            display: inline-block;
            box-sizing: border-box;
            padding: 2rem;
            margin: 0.5rem;
            border: 0.1rem solid $cdpink;
            border-radius: $border-radius;
            cursor: pointer;
        }

        input[type="radio"]:checked ~ label {
            box-shadow: 4px 4px 20px $cdpink;
        }
    }

    &__transfer {
        label {
            display: block;
            box-sizing: border-box;
            padding: 2rem;
            margin: 0.5rem;
            border: 0.1rem solid $cdpink;
            border-radius: $border-radius;
            cursor: pointer;
        }
    }
}
</style>
