<template>
    <div class="dropdown">
        <div class="input-group dropdown__selected-item px-4 py-2" v-if="selectedItem">
            <div class="d-flex justify-content-between flex-fill align-items-center">
                <span>
                    <i class="fas fa-map-marker-alt"></i>
                     {{ selectedItem.ilce + ', ' + selectedItem.semt + ', ' +  selectedItem.mahalle }}
                </span>
                <i
                    class="btn fas fa-times"
                    @click="removeItem"
                ></i>
            </div>
        </div>

        <div class="input-group" v-if="!selectedItem">
            <input class="form-control px-4 my-2"
                   type="text"
                   :name="inputName"
                   v-model="query"
                   :placeholder="placeholder"
                   autocomplete="off"
                   v-on:focus="handleFocus"
                   v-on:blur="handleBlur"
            >
        </div>

        <ul class="data-list" v-show="open">
            <li
                class="data-list__item px-4 py-4"
                v-for="item in showingItems"
                v-bind:value="item.ilce"
                v-on:click="selectItem(item)"
            >
                <i class="fas fa-map-marker-alt"></i>
                {{ item.ilce }}, {{ item.semt }} , {{ item.mahalle }}
            </li>
        </ul>
    </div>
</template>
<script>
    const makeSortString = (function() {
        const translate_re = /[ığüşöçİĞÜŞÖÇ]/ig;
        const translate = {
            "ı": "i", "ğ": "g", "ü": "u", "ş": "s", "ö": "o", "ç": "c",
            "İ": "I", "Ğ": "G", "Ü": "U", "Ş": "S", "Ö": "o", "Ç": "C",
        };
        return function(s) {
            return ( s.replace(translate_re, function(match) {
                return translate[match];
            }) );
        }
    })();

    export default {
        data() {
            return {
                items: null,
                query: '',
                open: false,
                showingItems: null
            }
        },
        props: {
            placeholder: String,
            inputName: String,
            selectedItem: Object
        },
        methods: {
            selectItem(item) {
                this.query = ''

                this.$emit('input', { ...item })
            },
            handleFocus() {
                this.open = true
            },
            handleBlur() {
                setTimeout(() => this.open = false, 250)
            },
            removeItem() {
                this.$emit('removed')
            }
        },
        watch: {
            query() {
                let query = makeSortString(this.query)
                if (this.query && this.query.length > 0) {
                    this.showingItems = this.items.filter(
                        city => makeSortString(city.il).match(new RegExp("^" + query, 'iu')) ||
                            makeSortString(city.ilce).match(new RegExp("^" + query, 'iu')) ||
                            makeSortString(city.semt).match(new RegExp("^" + query, 'iu')) ||
                            makeSortString(city.mahalle).match(new RegExp("^" + query, 'iu'))
                    );
                } else {
                    this.showingItems = this.items
                }
            }
        },
        created() {
            axios.get('/api/yer/istanbul')
                .then(response => {
                    this.items = response.data
                    this.showingItems = response.data
                })
        }
    }
</script>
<style lang="scss" scoped>
    @import '~@/_variables.scss';

    .dropdown {
        &__selected-item {
            color: white;
            background-color: $cdpink;
            width: 100%;
        }
    }
</style>
