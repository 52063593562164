<template>
    <li class="menu-item">
        <a class="tab-selector" v-on:click="handleClick" :href="kategori['kategori-link'] || null "
           :title="kategori['kategori-link'] ? kategori['kategori-grup'] : null">

            <span>
                <i :class="kategori['kategori-ikon']"></i> {{ kategori['kategori-grup'] }}
            </span>
        </a>
        <transition name="fade">
            <div class="container tab-content" v-if="kategori['kategori-items']" v-show="show">
                <span v-for="(item, key) in kategori['kategori-items']">
                    <i class="fas fa-angle-double-right"></i>
                    <a :href="key">
                        {{ item }}
                    </a>
                </span>
            </div>
        </transition>
    </li>
</template>
<script>
    export default {
        data() {
            return {
                show: window.matchMedia('(min-width: 64em)').matches
            }
        },
        props: ['kategori'],
        methods: {
            handleClick() {
                if(window.matchMedia('(max-width: 64em)').matches){
                    this.show = !this.show;
                }
            },
            onResize(){
                if(window.matchMedia('(min-width: 64em)').matches){
                    this.show = true;
                }else {
                    this.show = false;
                }
            }
        },
        created() {
            window.addEventListener('resize', this.onResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        }
    }
</script>
<style scoped lang="scss">
    .fade-enter-active, .fade-leave-active {
        transition: max-height .5s ease-in-out;
        overflow: hidden;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
    {
        max-height: 0;
    }
</style>
