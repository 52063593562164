<template>
    <div class="order-submit">
        <button
            class="btn btn--color-white btn--bg-pink my-2 ml-auto mr-auto"
            v-if="this.siparisOlusturuldu"
            type="submit"
        >
            Sipariş Bilgilerini Güncelle
        </button>
        <button
            class="btn btn--color-white btn--bg-pink my-2 ml-auto mr-auto"
            v-else
            type="submit"
        >
            Devam Et
        </button>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Submit",
    computed: {
        ...mapGetters('sepet', [
            'sepetId',
        ]),
        siparisOlusturuldu() {
            return this.sepetId !== undefined && this.sepetId !== null;
        }
    }

}
</script>
