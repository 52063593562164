<template>
    <transition name="slide" v-if="this.showSummary">
        <div class="overlay"
             @click="this.toggleSummary"
        ></div>
    </transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "Overlay.vue",
    methods: {
        ...mapActions('ui', [
            'toggleSummary'
        ])
    },
    computed: {
        ...mapGetters('ui', [
            'showSummary'
        ])
    }
}
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
    transition: width .5s;
}

.slide-enter,
.slide-leave-to {
    width: 0;
}
</style>
