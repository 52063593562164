<template>
    <div class="search-box">
        <form method="POST" :action="action">
            <input type="hidden" name="_token" :value="csrf_token">
            <div class="input-group">
                <input class="form-control m-2 mr-5 pl-4 pr-5" name="query" type="text" placeholder="Ne Aramıştınız ?" v-model="query">
                <button class="btn btn--search fas fa-search my-3"></button>
            </div>
        </form>
        <ul class="search-data-list" v-if="results.length > 0 && query">
            <li class="search-data-list__item--search p-2" v-for="result in results.slice(0,9)" :key="result.id">
                <a :href="result.slug">
                    <img :src="'/buyukresim/' + result.resim">
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        data() {
            return {
                query: null,
                results: []
            };
        },

        watch: {
            query(after, before) {
                if (this.query && this.query.length > 2) {
                    this.searchMembers();
                }
            }
        },
        methods: {
            searchMembers() {
                axios.get(this.instant, {params: {query: this.query}})
                    .then(response => this.results = response.data)
                    .catch(error => {
                    });
            }
        },
        props: ['csrf_token', 'action', 'instant']
    }
</script>
