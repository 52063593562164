<template>
  <div class="container">
    <div class="row info-form__title">
      <h2>{{ title }}</h2>
    </div>
    <div class="container">
      <slot name="inputs" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String
  }
};
</script>
