import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const defaultState = {
    bizemesaj: "",
    smsvarmi: true,
    odemetipi: "kredikarti",
    faturavarmi: false,
    ayniadresikullan: true,
    siparisler: {},
    secilmisSiparis: null,
    id: null
};

export default {
    namespaced: true,
    state: () => ({...defaultState}),
    getters,
    mutations,
    actions,
};
