<template>
    <form-section title="Çiçek Kart Bilgileri">
        <template v-slot:inputs>
            <div class="row">
                <div class="col-12">
                    <form-textarea
                        id="kartMesaj"
                        name="kartMesaj"
                        label="Kart Mesajınız"
                        placeholder
                        :rows="4"
                        :required="false"
                        v-model="yazi"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <form-input
                        id="kartIsim"
                        name="kartIsim"
                        type="text"
                        label="Gönderici İsmi"
                        placeholder
                        :required="false"
                        v-model="isim"
                    />
                </div>
                <div class="col-lg-6">
                    <form-input
                        id="kartIsmimgorunmesin"
                        name="kartIsmimgorunmesin"
                        type="checkbox"
                        label="Siparişim isimsiz gitsin."
                        placeholder
                        :required="false"
                        v-model="ismimgorunmesin"
                    />
                </div>
            </div>
        </template>
    </form-section>
</template>
<script>
import FormInput from "../../FormItems/FormInput";
import FormTextarea from "../../FormItems/FormTextarea";
import FormSection from "../../FormItems/FormSection";
import { mapGetters, mapActions } from "vuex";


export default {
    components: {
        FormInput,
        FormTextarea,
        FormSection
    },
    methods: {
        ...mapActions('sepet', [
            'setKart'
        ])
    },
    computed: {
        ...mapGetters('sepet', [
            'kart'
        ]),
        yazi: {
            get() {
                return this.kart.yazi;
            },
            set(value) {
                this.setKart({
                    kart: {
                        ...this.kart,
                        yazi: value
                    }
                })
            }
        },
        isim: {
            get() {
                return this.kart.isim;
            },
            set(value) {
                this.setKart({
                    kart: {
                        ...this.kart,
                        isim: value
                    }
                })
            }
        },
        ismimgorunmesin: {
            get() {
                return this.kart.ismimgorunmesin;
            },
            set(value) {
                this.setKart({
                    kart: {
                        ...this.kart,
                        ismimgorunmesin: value
                    }
                })
            }
        }
    }
};
</script>
