<template>

    <div class="cart-summary">
        <section class="cart-summary__item my-2 p-3" v-for="(order, index) in Object.values(cart)" :key="index">
            <span class="cart-summary__item__index"> {{ index + 1 }}</span>
            <h3>{{ order.urun.ad }}</h3>
            <span class="cart-summary__item__date">
                        Gönderileceği Tarih:
                         {{ toLocaleDate(order.teslimtarihi) }}
                    </span>
            <span class="cart-summary__item__price">
                         {{ order.urun.fiyat.toFixed(2) }} ₺
                    </span>
            <i
                class="cart-summary__item__remove fas fa-trash-alt"
                @click="deleteSiparis(order.sepetici_id)"
            />

            <div class="cart-summary__item__extras" v-if="checkOrderHasEkstra(order)">
                <div class="cart-summary__item__extra"
                     v-for="(extra, index) in order.ekstralar"
                     :key="index"
                >
                    <img
                        :src="'/kucukresim/' + extra.resim"
                        class="cart-summary__item__extra__img"
                    >
                    <span class="cart-summary__item__extra__price">
                        {{ extra.fiyat.toFixed(2) }} ₺
                    </span>
                    <span class="cart-summary__item__extra__count">
                        {{ extra.adet }}
                    </span>
                    <i
                        class="cart-summary__item__extra__remove fas fa-trash-alt"
                        @click="removeExtraItem({
                            siparisId: order.sepetici_id,
                            extraItem: extra
                        })"
                    />
                </div>
            </div>
        </section>

        <section class="cart-summary__total">
                        <span class="cart-view__summary__total__price d-flex justify-content-between">
                            <b>Total:</b>
                             {{ totalPrice.toFixed(2) }}
                             ₺
                        </span>
            <span class="cart-view__summary__total__kdv d-flex justify-content-between">
                            <b>KDV(18%):</b>
                             {{ kdv.toFixed(2) }}
                             ₺
                        </span>
            <span class="cart-view__summary__total__total tag tag--in d-flex justify-content-between">
                            <b>Toplam:</b>
                             {{ (totalPrice + kdv).toFixed(2) }}
                             ₺
                        </span>
        </section>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {toLocaleDate} from "../../utils";
import {calculateTotalPrice} from "./utils";

export default {
    methods: {
        ...mapActions('sepet', [
            'deleteSiparis',
            'removeExtraItem'
        ]),
        toLocaleDate,
        calculateTotalPrice,
        checkOrderHasEkstra(order) {
            if (order.hasOwnProperty("ekstralar")) {
                return Object.keys(order.ekstralar).length > 0;
            }
            return false;
        }
    },
    computed: {
        ...mapGetters('sepet', {
            cart: 'siparisler'
        }),
        totalPrice() {
            return this.calculateTotalPrice(this.cart)
        },

        kdv() {
            return 0.18 * this.totalPrice
        }
    }
}
</script>
<style lang="scss" scoped>
@import "~@/_variables.scss";

.cart-summary {
    &__item {
        display: flex;
        flex-direction: column;
        border: .1rem solid $cdpink;
        border-radius: $border-radius;
        position: relative;
        overflow: auto;
        height: 100%;

        &__date,
        &__price {
            font-size: $font-size-small;
        }

        &__index,
        &__remove {
            position: absolute;
            top: 1%;
        }

        &__index {
            left: 1%;
        }

        &__remove {
            right: 1%;
            cursor: pointer;
        }

        &__extras {
            border: .1rem solid lightslategrey;
            border-radius: $border-radius;
        }

        &__extra {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-around;
            margin-top: 1rem;

            &__img {
                width: 30%;
                height: auto;
            }

            &__remove {
                cursor: pointer;
            }
        }
    }

    &__total {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        &__total {
            font-size: $font-size-big;
        }
    }
}
</style>
