import axios from "axios";

export const createSepet = (sepet) => {
    const apiPath = "/api/sepet"
    return axios.post(apiPath, sepet)
        .then(res => res.data)
        .catch(err => console.error(err))
}

export const updateSepet = (sepet) => {
    const apiPath = `/api/sepet/${sepet.id}`;
    return axios.post(apiPath, sepet)
        .then(res => res.data)
        .catch(err => console.error(err));
}
