import * as pages from "./constants"
import ExtraProductPage from "../components/order/ExtraProductsPage";
import OrderInformationPage from "../components/order/OrderInformationPage";

const routes = [
    {
        path: pages.EXTRA_PAGE,
        component: ExtraProductPage
    },
    {
        path: pages.ORDER_DETAILS,
        component: OrderInformationPage
    }
]

export default routes;
