<template>
    <div class="extra-item">
        <div class="container">
            <img class="extra-item__img" :src="'/kucukresim/' + item.resim">
            <h2 class="extra-item__title"> {{ item.baslik }}</h2>
            <div class="extra-item__quantity">
                <i
                    class="fas fa-minus"
                    @click="decrementCount"
                />
                <p>{{ count }}</p>
                <i
                    class="fas fa-plus"
                    @click="incrementCount"
                />
            </div>
            <p class="extra-item__price"> {{ item.fiyat.toFixed(2) }} ₺</p>
        </div>
    </div>
</template>
<script>

import {mapActions, mapGetters} from 'vuex'
import Swal from 'sweetalert2'

export default {
    props: {
        item: Object,
    },

    methods: {
        ...mapActions('sepet', [
            'addExtraItem',
            'removeExtraItem'
        ]),
        incrementCount() {
            if (!this.checkOrder()) {
                return false
            }
            if (this.count < 5) {
                this.addExtraItem({
                    siparisId: this.secilmisSiparis,
                    extraItem: this.item
                })
                return true
            }
            return false
        },

        decrementCount() {
            if (!this.checkOrder()) {
                return false
            }

            this.removeExtraItem({
                siparisId: this.secilmisSiparis,
                extraItem: this.item
            })
            return true
        },
        checkOrder() {
            if (!this.secilmisSiparis) {
                Swal.fire("Oops!!!", "Ekstra ürün eklenecek herhangi bir çiçek seçili değil...", "error")
                return false
            }
            return true
        }
    },
    computed: {
        ...mapGetters('sepet', [
            'secilmisSiparis',
            'extraCount',
        ]),

        count() {
            return this.extraCount(this.secilmisSiparis, this.item);
        }
    }
}
</script>
