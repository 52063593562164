<template>
    <form-section title="Teslimat Bilgileri">
        <template v-slot:inputs>
            <div class="row">
                <div class="col-12">
                    <form-textarea
                        id="bizemesaj"
                        name="bizemesaj"
                        label="Varsa Bize Notunuz"
                        placeholder
                        :rows="4"
                        :required="false"
                        v-model="bizemesaj"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <form-input
                        id="smsvarmi"
                        name="smsvarmi"
                        type="checkbox"
                        label="Teslim SMSi gönderin."
                        placeholder
                        :required="false"
                        v-model="smsvarmi"
                    />
                </div>
            </div>
        </template>
    </form-section>
</template>
<script>
import FormInput from "../../FormItems/FormInput";
import FormTextarea from "../../FormItems/FormTextarea";
import FormSection from "../../FormItems/FormSection";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        FormInput,
        FormTextarea,
        FormSection
    },
    methods: {
        ...mapActions('sepet', [
            'setSmsvarmi',
            'setBizemesaj'
        ])
    },
    computed: {
        ...mapGetters('sepet', {
            smsvarmiValue: 'smsvarmi',
            bizemesajValue: 'bizemesaj'
        }),
        smsvarmi: {
            get() {
                return this.smsvarmiValue;
            },
            set(value) {
                this.setSmsvarmi(value);
            }
        },
        bizemesaj:{
            get() {
                return this.bizemesajValue;
            },
            set(value) {
                this.setBizemesaj(value);
            }
        }
    }
};
</script>
