import * as types from "./types";
import { createSepet, updateSepet } from "../../../api/sepet";
import Swal from 'sweetalert2'

const actions = {
    setAlici({commit}, {alici}) {
        commit(types.SET_ALICI, {alici});
    },
    setGonderen({commit}, {gonderen}) {
        commit(types.SET_GONDEREN, {gonderen});
    },
    setKart({commit}, {kart}) {
        commit(types.SET_KART, {kart});
    },
    setUrun({commit}, {sepeticiId, urun}) {
        commit(types.SET_URUN, {sepeticiId, urun});
    },
    createSiparis({commit}, siparis) {
        commit(types.CREATE_SIPARIS, {siparis})
    },
    setSecilmisSiparis({commit}, siparisId) {
        commit(types.SET_SECILMIS_SIPARIS, {siparisId})
    },
    deleteSiparis({dispatch, commit, state}, siparisId) {
        if (state.secilmisSiparis === siparisId) {
            dispatch("setSecilmisSiparis", null);
        }
        commit(types.DELETE_SIPARIS, {siparisId})
    },
    addExtraItem({commit}, {siparisId, extraItem}) {
        commit(types.ADD_EXTRA_ITEM, {siparisId, extraItem})
    },
    removeExtraItem({commit}, {siparisId, extraItem}) {
        commit(types.DELETE_EXTRA_ITEM, {siparisId, extraItem});
    },
    emptyCart({dispatch, commit}) {
        dispatch("setSecilmisSiparis", null);
        commit(types.EMPTY_SEPET);
    },
    toggleReceipt({commit}) {
        commit(types.TOGGLE_RECEIPT);
    },
    setBizemesaj({commit}, bizemesaj) {
        commit(types.SET_BIZEMESAJ, {bizemesaj});
    },
    setSmsvarmi({commit}, smsvarmi) {
        commit(types.SET_SMSVARMI, {smsvarmi})
    },
    submitCart({commit, state}) {
        function checkObjectDefined(obj) {
            return obj !== undefined && obj !== null;
        }
        function isCartCreated() {
            return checkObjectDefined(state.id);
        }

        function isOrdersCreated() {
            return Object.values(state.siparisler).reduce((acc, current) => {
                return acc && checkObjectDefined(current.id)
            }, true)
        }

        function isCartAndOrdersCreated() {
            return isCartCreated() && isOrdersCreated();
        }

        if (isCartCreated()){
            return updateSepet(state).then(() => {
                commit(types.UPDATE_CART);
                return Swal.fire('Teşekkür Ederiz...', 'Sepetiniz Güncellendi', 'success')
            })
        } else {
            return createSepet(state).then((data) => {
                commit(types.SUBMIT_CART, {sepet: data.sepet})
                return Swal.fire('Teşekkür Ederiz...', 'Sepetiniz Başarıyla Oluşturuldu', 'success')
            })
        }
    },
    toggleCommonAddress({commit}) {
        commit(types.TOGGLE_COMMON_ADDRESS)
    },
    setOdemetipi({commit}, odemetipi) {
        commit(types.SET_ODEMETIPI, {odemetipi})
    }
};

export default actions;
