<template>
    <form-section title="Fatura Bilgileri">
        <template v-slot:inputs>
            <div class="row">
                <div class="col-6">
                    <input
                        id="kurumsalFatura"
                        type="radio"
                        v-model="faturaTipi"
                        value="kurumsalFatura"
                        name="faturaTipi"
                    >
                    <label for="kurumsalFatura">Kurumsal Fatura</label>
                </div>

                <div class="col-6">
                    <input
                        id="bireyselFatura"
                        type="radio"
                        v-model="faturaTipi"
                        value="bireyselFatura"
                        name="faturaTipi"
                    />
                    <label for="bireyselFatura">Bireysel Fatura</label>
                </div>
            </div>
            <kurumsal v-if="faturaTipi === 'kurumsalFatura'"/>
            <bireysel v-else-if="faturaTipi === 'bireyselFatura'"/>
            <div class="row">
                <div class="col-6">
                    <input
                        id="eFaturaVar"
                        type="radio"
                        v-model="eFatura"
                        :value="true"
                        name="eFaturaVarMi"
                    >
                    <label for="eFaturaVar">E-fatura mükellefiyim.</label>
                </div>
                <div class="col-6">
                    <input
                        id="eFaturaYok"
                        name="eFaturaVarMi"
                        type="radio"
                        v-model="eFatura"
                        :value="false"
                    >
                    <label for="eFaturaYok">E-fatura mükellefi değilim.</label>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <form-input
                        id="eFaturaEPosta"
                        name="eFaturaEPosta"
                        type="text"
                        v-model="ePosta"
                        :label="ePostaLabel"
                        :required="true"
                    />
                </div>
            </div>
        </template>
    </form-section>
</template>
<script>
    import {FormSection, FormInput} from "../../FormItems";
    import Kurumsal from "./Kurumsal";
    import Bireysel from "./Bireysel";

    export default {
        components: {
            FormSection,
            FormInput,
            Kurumsal,
            Bireysel
        },
        data() {
            return {
                faturaTipi: "kurumsalFatura",
                eFatura: true,
                ePosta: "",
            };
        },
        computed: {
            ePostaLabel() {
                if (this.eFatura) {
                    return "E-fatura'nın Gönderileceği E-posta Adresi"
                } else {
                    return "Fatura nüshasının gönderileceği E-posta Adresi"
                }
            }
        }
    };
</script>
