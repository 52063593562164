import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

export const defaultState = {
    showSummary: true,
}

export default {
    namespaced: true,
    state: () => ({...defaultState}),
    getters,
    mutations,
    actions
}
