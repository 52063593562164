import {createComputedObject} from './order'

export function extractPlaceName(place) {
    return place['ilce'] + ', ' + place['semt'] + ', ' + place['mahalle']
}

export function toLocaleDate(date) {
    return new Date(date)
        .toLocaleDateString(
            'tr-TR',
            {
                year: 'numeric',
                month: 'long',
                weekday: 'long',
                day: 'numeric'
            }
        )
}

export {
    createComputedObject
}
