<template>
    <form-section title="Alıcı Bilgileri">
        <template v-slot:inputs>
            <div class="row">
                <div class="col-lg-6">
                    <form-input
                        id="aliciAdsoyad"
                        name="aliciAdsoyad"
                        type="text"
                        label="Alıcının Adı Soyadı"
                        placeholder
                        :rules="'required'"
                        v-model="adsoyad"
                    />
                </div>
                <div class="col-lg-6">
                    <form-input
                        id="aliciTelefon"
                        name="aliciTelefon"
                        type="tel"
                        label="Alıcının Telefon Numarası"
                        placeholder
                        :rules="'required|numeric'"
                        v-model="telefon"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <form-select
                        id="aliciYer"
                        name="aliciYer"
                        v-model="yer"
                        :rules="'required'"
                        :items="yerler"
                        :map="extractPlaceName"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <form-textarea
                        id="aliciAdres"
                        name="aliciAdres"
                        label="Teslimat Adresi"
                        placeholder
                        :rows="4"
                        :rules="'required'"
                        v-model="adres"
                    />
                </div>
            </div>
        </template>
    </form-section>
</template>
<script>
import axios from "axios";

import FormSection from "../../FormItems/FormSection";
import FormInput from "../../FormItems/FormInput";
import FormSelect from "../../FormItems/FormSelect";
import FormTextarea from "../../FormItems/FormTextarea";
import {extractPlaceName} from "../../../../utils";
import {VueTelInput} from "vue-tel-input";
import {mapGetters, mapActions} from "vuex";

export default {
    data() {
        return {
            yerler: []
        };
    },
    components: {
        FormSection,
        FormInput,
        FormSelect,
        FormTextarea,
        VueTelInput
    },
    methods: {
        extractPlaceName,
        ...mapActions('sepet', {
            setAlici: 'setAlici'
        })
    },
    created() {
        axios.get("/api/yer/istanbul").then(response => {
            this.yerler = response.data;
        });
    },
    computed: {
        ...mapGetters('sepet', [
            'alici',
        ]),
        adsoyad: {
            get() {
                return this.alici.adsoyad;
            },
            set(value) {
                this.setAlici({
                    alici: {
                        ...this.alici,
                        adsoyad: value
                    }
                })
            }
        },
        telefon: {
            get() {
                return this.alici.telefon;
            },
            set(value) {
                this.setAlici({
                    alici: {
                        ...this.alici,
                        telefon: value
                    }
                })
            }
        },
        yer: {
            get() {
                return this.alici.adres.yer;
            },
            set(value) {
                this.setAlici({
                    alici: {
                        ...this.alici,
                        adres: {
                            ...this.alici.adres,
                            yer: value
                        }
                    }
                })
            }
        },
        adres: {
            get() {
                return this.alici.adres.adres;
            },
            set(value) {
                this.setAlici({
                    alici: {
                        ...this.alici,
                        adres: {
                            ...this.alici.adres,
                            adres: value
                        }
                    }
                })
            }
        }
    }
};
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
