<template>
    <div class="container cart-view" v-if="Object.keys(cart).length > 0">
        <div class="row justify-content-between">
            <section class="col-md-8">
                <article class="cart-view__item p-3 my-4" v-for="(item, index) in Object.values(cart)" :key="index">
                    <img class="cart-view__item__img" :src="'/buyukresim/' + item.urun.resim.buyukresim1">

                    <section class="cart-view__item__info mx-3 my-0">
                        <h2>{{ item.urun.ad }}</h2>
                        <span>
                            <b>
                                Gönderileceği Tarih:
                            </b>
                             {{ toLocaleDate(item.teslimtarihi) }}
                        </span>
                        <span>
                            <b>
                                Gönderileceği Yer:
                            </b>
                             {{ extractPlaceName(item.alici.adres.yer) }}
                        </span>
                        <span>
                            {{ item.urun.fiyat.toFixed(2) }} ₺
                        </span>
                    </section>

                    <i
                        class="cart-view__item__remove fas fa-times fa-2x my-1"
                        @click="deleteSiparis(item.sepetici_id)"
                    />
                </article>
            </section>

            <section class="col-md-3">
                <cart-summary class="cart-view__summary my-4"/>
                <button
                    class="btn btn--w-100 btn--bg-pink btn--color-white"
                    @click="placeOrder"
                >
                    Satın Al
                </button>

                <button
                    class="btn btn--w-100 btn--bg-pink btn--color-white mt-2"
                    @click="emptyCart"
                >
                    Sepeti Boşalt
                </button>
            </section>
        </div>
    </div>
    <div class="cart-view--no-item d-flex flex-fill justify-content-center" v-else>
        <p class="my-5">
            Sepetinizde Herhangi Bir Ürün Bulunmamaktadır.
        </p>
    </div>
</template>
<script>
    import {extractPlaceName, toLocaleDate} from "../../utils"
    import {mapGetters, mapActions} from 'vuex'
    import CartSummary from './CartSummary'

    export default {
        components: {
            CartSummary
        },
        computed: {
            ...mapGetters('sepet', {
                cart: 'siparisler'
            })
        },
        methods: {
            ...mapActions('sepet', [
                'deleteSiparis',
                'emptyCart',
            ]),

            toLocaleDate,
            extractPlaceName,
            placeOrder() {
                window.location.href = '/siparis/ekstralar'
            },
        }
    }
</script>
<style lang="scss" scoped>
    @import "~@/_variables.scss";

    .cart-view {
        box-sizing: border-box;
        background-color: white;
        min-height: 100vh;

        &__item {
            display: flex;
            flex-flow: row nowrap;
            border: .1rem solid $cdpink;
            position: relative;
            align-items: flex-start;

            &__img {
                width: 25%;
                height: auto;
            }

            &__info {
                display: flex;
                flex-direction: column;
            }

            &__remove {
                position: absolute;
                top: 0;
                right: 1%;
                cursor: pointer;
            }
        }

        &--no-item {
            height: 100vh;
        }

        &__summary {
            box-sizing: border-box;
            padding: 1rem;
            border: .1rem solid lightgray;
            border-radius: $border-radius;
        }
    }
</style>

