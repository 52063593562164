<template>
    <form-input-layout :rules="rules">
        <template v-slot:input>
            <select
                :id="id"
                :name="name"
                placeholder
                v-model="selected"
            >
                <option
                    v-for="(item, index) in items"
                    :key="index"
                    :value="item"
                    >{{ map(item) }}</option
                >
            </select>
        </template>
    </form-input-layout>
</template>
<script>
import FormInputLayout from "./FormInputLayout";

export default {
    props: {
        id: String,
        name: String,
        value: [Object, String],
        items: Array,
        map: {
            type: Function,
            default: item => item
        },
        rules: String
    },
    data() {
        return {
            selected: this.value
        };
    },
    components: {
        FormInputLayout
    },
    watch: {
        selected(newValue) {
            this.$emit('input', newValue)
        }
    }
};
</script>
