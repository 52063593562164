require('./bootstrap');

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import store from './store'
import router from './router'
import VuePaycard from "vue-paycard";

Vue.use(VuePaycard);
Vue.use(Vuex);
Vue.use(VueRouter);

Vue.directive('scroll', {
    inserted: function (el, binding) {
        let f = function (evt) {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f)
            }
        }
        window.addEventListener('scroll', f)
    }
});

Vue.component(
    'search',
    require('./components/layout/Search.vue').default
);

Vue.component(
    'sticky-header',
    require('./components/layout/StickyHeader.vue').default
);

Vue.component(
    'hover-tabs',
    require('./components/layout/HoverTabs.vue').default
);

Vue.component(
    'add-to-cart-from',
    require('./components/product/AddToCartForm').default
);

Vue.component(
    'carousel',
    require('./components/product/Carousel').default
);

Vue.component(
    'vertical-tabs',
    require('./components/product/VerticalTabs').default
);

Vue.component(
    'shopping-cart',
    require('./components/cart/Cart').default
);

Vue.component(
    'cart-view',
    require('./components/cart/CartView').default
);

Vue.component(
    'order-select',
    require('./components/order/OrderSelect').default
)

Vue.component(
    'overlay',
    require('./components/cart/Overlay').default
)

Vue.component(
    'extra-products-page',
    require('./components/order/ExtraProductsPage').default
)

Vue.component(
    'next-order-page-button',
    require('./components/order/NextOrderPageButton').default
)

Vue.component(
    'order-show-summary-button',
    require('./components/order/OrderShowSummaryButton').default
)

Vue.component(
    'order-progressbar',
    require('./components/order/OrderProgressbar').default
)

Vue.component(
    'order-summary',
    require('./components/order/OrderSummary').default
)

Vue.component(
    'cart-summary',
    require('./components/cart/CartSummary').default
)

Vue.component(
    'order-information-page',
    require('./components/order/OrderInformationPage').default
)

Vue.component(
    'order-payment',
    require('./components/order/OrderPayment').default
)

const app = new Vue({
    el: '#app',
    store,
    router
});

export default app;
