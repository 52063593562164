<template>
    <form-section title="Gönderici Bilgileri">
        <template v-slot:inputs>
            <div class="row">
                <div class="col-lg-6">
                    <form-input
                        id="gonderenAdsoyad"
                        name="gonderenAdsoyad"
                        type="text"
                        label="Adınız Soyadınız"
                        placeholder
                        :rules="'required'"
                        v-model="adsoyad"
                    />
                </div>
                <div class="col-lg-6">
                    <form-input
                        id="gonderenTelefon"
                        name="gonderenTelefon"
                        type="tel"
                        label="Telefonunuz"
                        placeholder
                        :rules="'required|numeric'"
                        v-model="telefon"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <form-input
                        id="gonderenEposta"
                        name="gonderenEposta"
                        type="email"
                        label="E-posta adresiniz"
                        placeholder
                        :rules="'required|email'"
                        v-model="eposta"
                    />
                </div>
            </div>
        </template>
    </form-section>
</template>
<script>
import FormSection from "../../FormItems/FormSection";
import FormInput from "../../FormItems/FormInput";
import { mapGetters, mapActions } from "vuex";


export default {
    components: {
        FormSection,
        FormInput
    },
    methods: {
        ...mapActions('sepet', [
            'setGonderen'
        ])
    },
    computed: {
        ...mapGetters('sepet',[
            'gonderen'
        ]),
        adsoyad: {
            get() {
                return this.gonderen.adsoyad;
            },
            set(value) {
                this.setGonderen({
                    gonderen: {
                        ...this.gonderen,
                        adsoyad: value
                    }
                })
            }
        },
        telefon: {
            get() {
                return this.gonderen.telefon;
            },
            set(value) {
                this.setGonderen({
                    gonderen: {
                        ...this.gonderen,
                        telefon: value
                    }
                })
            }
        },
        eposta: {
            get() {
                return this.gonderen.eposta;
            },
            set(value) {
                this.setGonderen({
                    gonderen: {
                        ...this.gonderen,
                        eposta: value
                    }
                })
            }
        }
    }
};
</script>
