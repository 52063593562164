<template>
    <div class="info-form__input-group">
        <ValidationProvider :rules="rules" v-slot="{ errors }">
            <slot name="input"/>
            <label v-if="id && label" :for="id">{{ label }}</label>
            <small v-if="errors">{{ errors[0] }}</small>
        </ValidationProvider>
    </div>
</template>
<script>
import {extend, ValidationProvider} from "vee-validate";
import {email, required, numeric} from "vee-validate/dist/rules";

const niceFieldName = (fieldCamelCase) => {
    const niceFieldNameNotCapitalized = fieldCamelCase.replace(/([A-Z])/g, " $1");
    return niceFieldNameNotCapitalized.charAt(0).toUpperCase() + niceFieldNameNotCapitalized.slice(1);
}

extend('required', {
    ...required,
    message: (fieldName) => {
        return `${niceFieldName(fieldName)} alanını lütfen doldurunuz...`
    }
})

extend('email', {
    ...email,
    message: "Lütfen geçerli bir mail adresi giriniz..."
})

extend('numeric', {
    ...numeric,
    message: "Lütfen geçerli bir telefon numarası giriniz..."
})

export default {
    props: {
        id: String,
        label: String,
        rules: String
    },
    components: {
        ValidationProvider
    }
};
</script>
