<template>
    <div>
        <form
            class="form form--add-to-cart"
            id="add-to-cart"
        >
            <input type="hidden" name="_token" :value="csrf_token">

            <dropdown
                :placeholder="'Nereye Göndereceğinizi Yazınız ve Seçiniz...'"
                :input-name="'ilce'"
                :selectedItem="location"
                @input="handleLocationInput"
                @removed="handleLocationRemoved"
            />

            <input type="hidden" name="ilce" :value="location" v-if="location">

            <datepicker
                :calendarClass="['datepicker__calendar']"
                :inputClass="'datepicker__input px-4 my-2'"
                :placeholder="'Ne Zaman Göndereceksiniz ?'"
                v-model="date"
                :language="tr"
                :monday-first="true"
                :name="'date'"
                :disabled-dates="disableDates"
                :highlighted="highlighted"
                :bootstrap-styling="true"
                :required="true"
            />

            <select class="select-hours my-2 px-4 py-2" v-if="date && availableHours.length > 0"
                    v-model="selectedInterval">
                <option class="select-hours__item" v-for="interval in availableHours">
                    {{ parseInterval(interval) }}
                </option>
            </select>

            <div class="input-group">
                <div class="checkbox-container form-control px-4 my-2">
                    <label class="checkbox-label" for="basket">
                        <img :src="cart_icon">
                        <span><b>Özel taşıma çantası ister
                    misiniz?(+5TL)</b></span>
                    </label>
                    <input type="checkbox" id="basket">
                </div>
            </div>
        </form>

        <button
            class="btn btn--bg-pink btn--color-white btn--w-100 btn--hover-green my-2 p-3"
            @click="addToCart"
        >
            <i class="fas fa-shopping-cart"/>
            Sepete Ekle
        </button>

        <button
            type="submit"
            class="btn btn--bg-pink btn--color-white btn--w-100 btn--hover-green my-2 p-3"
            @click="placeOrder"
        >
            <i class="fas fa-angle-right"/>
            Siparişi Ver
        </button>

    </div>
</template>
<script>
import Datepicker from './Datepicker'
import Dropdown from './Dropdown'
import Swal from 'sweetalert2'
import axios from 'axios'
import {tr} from 'vuejs-datepicker/dist/locale'
import {mapActions} from 'vuex'

export default {
    components: {
        Datepicker,
        Dropdown
    },
    data() {
        return {
            showIlceList: false,
            location: null,
            date: null,
            mode: 'single',
            selectedDate: null,
            selectedInterval: null,
            availableHours: [],
            tr: tr,
            disableDates: {
                to: new Date(new Date().setDate(new Date().getDate() - 1)),
                from: new Date(new Date().setDate(new Date().getDate() + 31))
            },
            highlighted: {
                dates: [
                    new Date(2021, 1, 14),
                ],
            },
            isCalendarDisabled: false,
        }
    },

    props: [
        'csrf_token',
        'action',
        'cart_icon',
        'product',
    ],

    watch: {
        date() {
            axios.get('/api/available-hours', {params: {selectedDate: this.date}})
                .then(response => this.availableHours = response.data)
                .catch(e => {
                })
        }
    },

    methods: {
        ...mapActions('sepet', {
            pushOrderToCart: 'createSiparis',
        }),

        ...mapActions('information', [
            'setAddress'
        ]),

        parseInterval(interval) {
            let start = new Date(interval.start)
            let end = new Date(interval.end)

            let addHours = (num) => ("0" + num).slice(-2)
            let timeToString = (time) => addHours(time.getHours()) + ":" + addHours(time.getMinutes())

            return timeToString(start) + "-" + timeToString(end)
        },

        createOrder() {
            return {
                durum: "ÖDEME BEKLENİYOR",
                cantavarmi: false,
                arsiv: false,
                sepetici_id: Date.now(),
                teslimtarihi: this.date,
                teslimsaati: this.selectedInterval,
                alici: {
                  adsoyad: "",
                  telefon: "",
                  adres: {
                      yer: this.location,
                      adres: ""
                  }
                },
                gonderen: {
                    adsoyad: "",
                    telefon: "",
                    eposta: "",
                },
                kart: {
                    isim: "",
                    yazi: "",
                    ismimgorunmesin: false
                },
                ekstralar: [],
                urun: JSON.parse(this.product),
            }
        },

        addToCart() {
            if (this.validateInputs()) {
                const newOrder = {...this.createOrder()}
                this.pushOrderToCart(newOrder)

                return Swal.fire('Teşekkür Ederiz...', 'Ürününüz sepete eklendi', 'success')
            }
        },

        validateInputs() {
            if (this.location && this.date) {
                return true
            }
            Swal.fire('Dikkat', 'Lütfen Göndereceğiniz Yeri ve Zamanı Seçiniz', 'error')
            return false
        },

        handleLocationInput(location) {
            this.location = location;
        },

        handleLocationRemoved() {
            this.location = null;
        },

        placeOrder() {
            this.addToCart().then(res => {
                if (res.value) {
                    window.location.href = '/siparis/ekstralar'
                }
            })
        }
    },
}
</script>
<style lang="scss" scoped>
@import "~@/_variables.scss";

.select-hours {
    box-sizing: border-box;

    border: .1rem solid lightgray;
    border-radius: $border-radius;
    width: 100%;
    font-family: $font-family-roboto;
    position: relative;
}
</style>
