<template>
    <div class="menu">
        <input class="menu-btn" type="checkbox" id="menu-btn" v-model="show"/>
        <label class="menu-icon" for="menu-btn">
            <span class="nav-icon"></span>
        </label>
        <transition name="slide">
            <ul class="menu-list" v-show="show">
                <tab v-for="(kategori, key) in kategoriler" v-bind:kategori="kategori" v-bind:key="key"/>
            </ul>
        </transition>
        <transition name="slide">
            <div class="overlay" v-on:click="off" v-show="show"></div>
        </transition>
    </div>
</template>

<script>
    import axios from 'axios';
    import Tab from './Tab';

    export default {
        components: {
            Tab
        },
        data() {
            return {
                kategoriler: [],
                show: window.matchMedia('(min-width: 64em)').matches
            }
        },
        methods: {
            fetchData() {
                axios.get('/api/kategoriler')
                    .then(response => this.kategoriler = response.data)
            },
            off() {
                this.show = false
            },
            on() {
                this.show = true
            },
            onResize(){
                if(window.matchMedia('(min-width: 64em)').matches) {
                    this.show = true
                } else {
                    this.show = false
                }
            }
        },
        created() {
            this.fetchData();
            window.addEventListener('resize', this.onResize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        }
    }
</script>
