<template>
    <span
        class="order-header__show-button"
        @click="this.toggleSummary"
    >Sipariş Özeti</span>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "OrderShowSummaryButton",
    methods: {
        ...mapActions('ui', [
            'toggleSummary'
        ])
    }
}
</script>
