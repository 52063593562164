<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="order-form__checkbox">
                    <input id="fatura" type="checkbox" name="fatura" v-model="fatura"/>
                    <label for="fatura">Fatura bilgileri gir</label>
                </div>
            </div>
        </div>
        <receipt-form v-if="fatura"></receipt-form>
    </div>
</template>

<script>
import ReceiptForm from "../ReceiptForm/index";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Fatura",
    components: {
        ReceiptForm
    },

    computed: {
        ...mapGetters('sepet', [
            'faturavarmi'
        ]),

        fatura: {
            get() {
                return this.faturavarmi;
            },
            set() {
                this.toggleReceipt();
            }
        }
    },

    methods: {
        ...mapActions('sepet', [
            'toggleReceipt'
        ])
    }

}
</script>

<style scoped>

</style>
