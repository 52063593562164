<template>
    <div class="datepicker" :class="[wrapperClass, isRtl ? 'rtl' : '']">
        <date-input
            :selectedDate="selectedDate"
            :resetTypedDate="resetTypedDate"
            :format="format"
            :translation="translation"
            :inline="inline"
            :id="id"
            :name="name"
            :refName="refName"
            :openDate="openDate"
            :placeholder="placeholder"
            :inputClass="inputClass"
            :typeable="typeable"
            :clearButton="clearButton"
            :clearButtonIcon="clearButtonIcon"
            :calendarButton="calendarButton"
            :calendarButtonIcon="calendarButtonIcon"
            :calendarButtonIconContent="calendarButtonIconContent"
            :disabled="disabled"
            :required="required"
            :bootstrapStyling="bootstrapStyling"
            :use-utc="useUtc"
            @showCalendar="showCalendar"
            @closeCalendar="close"
            @typedDate="setTypedDate"
            @clearDate="clearDate">
            <slot name="afterDateInput" slot="afterDateInput"></slot>
        </date-input>

        <!-- Day View -->
        <picker-day
            v-if="allowedToShowView('day')"
            :pageDate="pageDate"
            :selectedDate="selectedDate"
            :showDayView="showDayView"
            :fullMonthName="fullMonthName"
            :allowedToShowView="allowedToShowView"
            :disabledDates="disabledDates"
            :highlighted="highlighted"
            :calendarClass="calendarClass"
            :calendarStyle="calendarStyle"
            :translation="translation"
            :pageTimestamp="pageTimestamp"
            :isRtl="isRtl"
            :mondayFirst="mondayFirst"
            :dayCellContent="dayCellContent"
            :use-utc="useUtc"
            @changedMonth="handleChangedMonthFromDayPicker"
            @selectDate="selectDate"
            @showMonthCalendar="showMonthCalendar"
            @selectedDisabled="selectDisabledDate">

            <div slot="beforeCalendarHeader" class="datepicker__calendar__header">
                <div class="datepicker__calendar__header__item px-3 py-4 mx-5 my-3"
                     @click="selectDate({ timestamp: new Date().getTime() })"
                >
                    Bugün
                </div>
                <div class="datepicker__calendar__header__item px-3 py-4 mx-5 my-3"
                     @click="selectDate({ timestamp: new Date(new Date().setDate(new Date().getDate() + 1)).getTime() })"
                >
                    Yarın
                </div>
                <div class="datepicker__calendar__header__item px-3 py-4 mx-5 my-3"
                     @click="selectDate({ timestamp: new Date(2020, 1, 14).getTime() })"
                >
                    Sevgililer Günü <i class="fas fa-heart"></i>
                </div>
            </div>

        </picker-day>
    </div>
</template>
<script>
    import DateInput from 'vuejs-datepicker/src/components/DateInput'
    import PickerDay from 'vuejs-datepicker/src/components/PickerDay'
    import utils, {makeDateUtils} from 'vuejs-datepicker/src/utils/DateUtils'

    export default {
        components: {
            DateInput,
            PickerDay
        },
        props: {
            value: {
                validator: val => utils.validateDateInput(val)
            },
            name: String,
            refName: String,
            id: String,
            format: {
                type: [String, Function],
                default: 'dd MMM yyyy'
            },
            language: {
                type: Object,
                default: () => en
            },
            openDate: {
                validator: val => utils.validateDateInput(val)
            },
            dayCellContent: Function,
            fullMonthName: Boolean,
            disabledDates: Object,
            highlighted: Object,
            placeholder: String,
            inline: Boolean,
            calendarClass: [String, Object, Array],
            inputClass: [String, Object, Array],
            wrapperClass: [String, Object, Array],
            mondayFirst: Boolean,
            clearButton: Boolean,
            clearButtonIcon: String,
            calendarButton: Boolean,
            calendarButtonIcon: String,
            calendarButtonIconContent: String,
            bootstrapStyling: Boolean,
            initialView: String,
            disabled: Boolean,
            required: Boolean,
            typeable: Boolean,
            useUtc: Boolean,
            minimumView: {
                type: String,
                default: 'day'
            },
            maximumView: {
                type: String,
                default: 'year'
            }
        },
        data() {
            const startDate = this.openDate ? new Date(this.openDate) : new Date()
            const constructedDateUtils = makeDateUtils(this.useUtc)
            const pageTimestamp = constructedDateUtils.setDate(startDate, 1)
            return {
                /*
                 * Vue cannot observe changes to a Date Object so date must be stored as a timestamp
                 * This represents the first day of the current viewing month
                 * {Number}
                 */
                pageTimestamp,
                /*
                 * Selected Date
                 * {Date}
                 */
                selectedDate: null,
                /*
                 * Flags to show calendar views
                 * {Boolean}
                 */
                showDayView: false,
                showMonthView: false,
                showYearView: false,
                /*
                 * Positioning
                 */
                calendarHeight: 0,
                resetTypedDate: new Date(),
                utils: constructedDateUtils
            }
        },
        watch: {
            value(value) {
                this.setValue(value)
            },
            openDate() {
                this.setPageDate()
            },
            initialView() {
                this.setInitialView()
            }
        },
        computed: {
            computedInitialView() {
                if (!this.initialView) {
                    return this.minimumView
                }

                return this.initialView
            },
            pageDate() {
                return new Date(this.pageTimestamp)
            },

            translation() {
                return this.language
            },

            calendarStyle() {
                return {
                    position: this.isInline ? 'static' : undefined
                }
            },
            isOpen() {
                return this.showDayView || this.showMonthView || this.showYearView
            },
            isInline() {
                return !!this.inline
            },
            isRtl() {
                return this.translation.rtl === true
            }
        },
        methods: {
            /**
             * Called in the event that the user navigates to date pages and
             * closes the picker without selecting a date.
             */
            resetDefaultPageDate() {
                if (this.selectedDate === null) {
                    this.setPageDate()
                    return
                }
                this.setPageDate(this.selectedDate)
            },
            /**
             * Effectively a toggle to show/hide the calendar
             * @return {mixed}
             */
            showCalendar() {
                if (this.disabled || this.isInline) {
                    return false
                }
                if (this.isOpen) {
                    return this.close(true)
                }
                this.setInitialView()
            },
            /**
             * Sets the initial picker page view: day, month or year
             */
            setInitialView() {
                const initialView = this.computedInitialView
                if (!this.allowedToShowView(initialView)) {
                    throw new Error(`initialView '${this.initialView}' cannot be rendered based on minimum '${this.minimumView}' and maximum '${this.maximumView}'`)
                }
                switch (initialView) {
                    case 'year':
                        this.showYearCalendar()
                        break
                    case 'month':
                        this.showMonthCalendar()
                        break
                    default:
                        this.showDayCalendar()
                        break
                }
            },
            /**
             * Are we allowed to show a specific picker view?
             * @param {String} view
             * @return {Boolean}
             */
            allowedToShowView(view) {
                const views = ['day', 'month', 'year']
                const minimumViewIndex = views.indexOf(this.minimumView)
                const maximumViewIndex = views.indexOf(this.maximumView)
                const viewIndex = views.indexOf(view)

                return viewIndex >= minimumViewIndex && viewIndex <= maximumViewIndex
            },
            /**
             * Show the day picker
             * @return {Boolean}
             */
            showDayCalendar() {
                if (!this.allowedToShowView('day')) {
                    return false
                }
                this.close()
                this.showDayView = true
                return true
            },
            /**
             * Show the month picker
             * @return {Boolean}
             */
            showMonthCalendar() {
                if (!this.allowedToShowView('month')) {
                    return false
                }
                this.close()
                this.showMonthView = true
                return true
            },
            /**
             * Show the year picker
             * @return {Boolean}
             */
            showYearCalendar() {
                if (!this.allowedToShowView('year')) {
                    return false
                }
                this.close()
                this.showYearView = true
                return true
            },
            /**
             * Set the selected date
             * @param {Number} timestamp
             */
            setDate(timestamp) {
                const date = new Date(timestamp)
                this.selectedDate = date
                this.setPageDate(date)
                this.$emit('selected', date)
                this.$emit('input', date)
            },
            /**
             * Clear the selected date
             */
            clearDate() {
                this.selectedDate = null
                this.setPageDate()
                this.$emit('selected', null)
                this.$emit('input', null)
                this.$emit('cleared')
            },
            /**
             * @param {Object} date
             */
            selectDate(date) {
                this.setDate(date.timestamp)
                if (!this.isInline) {
                    this.close(true)
                }
                this.resetTypedDate = new Date()
            },
            /**
             * @param {Object} date
             */
            selectDisabledDate(date) {
                this.$emit('selectedDisabled', date)
            },
            /**
             * @param {Object} month
             */
            selectMonth(month) {
                const date = new Date(month.timestamp)
                if (this.allowedToShowView('day')) {
                    this.setPageDate(date)
                    this.$emit('changedMonth', month)
                    this.showDayCalendar()
                } else {
                    this.selectDate(month)
                }
            },
            /**
             * @param {Object} year
             */
            selectYear(year) {
                const date = new Date(year.timestamp)
                if (this.allowedToShowView('month')) {
                    this.setPageDate(date)
                    this.$emit('changedYear', year)
                    this.showMonthCalendar()
                } else {
                    this.selectDate(year)
                }
            },
            /**
             * Set the datepicker value
             * @param {Date|String|Number|null} date
             */
            setValue(date) {
                if (typeof date === 'string' || typeof date === 'number') {
                    let parsed = new Date(date)
                    date = isNaN(parsed.valueOf()) ? null : parsed
                }
                if (!date) {
                    this.setPageDate()
                    this.selectedDate = null
                    return
                }
                this.selectedDate = date
                this.setPageDate(date)
            },
            /**
             * Sets the date that the calendar should open on
             */
            setPageDate(date) {
                if (!date) {
                    if (this.openDate) {
                        date = new Date(this.openDate)
                    } else {
                        date = new Date()
                    }
                }
                this.pageTimestamp = this.utils.setDate(new Date(date), 1)
            },
            /**
             * Handles a month change from the day picker
             */
            handleChangedMonthFromDayPicker(date) {
                this.setPageDate(date)
                this.$emit('changedMonth', date)
            },
            /**
             * Set the date from a typedDate event
             */
            setTypedDate(date) {
                this.setDate(date.getTime())
            },
            /**
             * Close all calendar layers
             * @param {Boolean} emitEvent - emit close event
             */
            close(emitEvent) {
                this.showDayView = this.showMonthView = this.showYearView = false
                if (!this.isInline) {
                    if (emitEvent) {
                        this.$emit('closed')
                    }
                    document.removeEventListener('click', this.clickOutside, false)
                }
            },
            /**
             * Initiate the component
             */
            init() {
                if (this.value) {
                    this.setValue(this.value)
                }
                if (this.isInline) {
                    this.setInitialView()
                }
            }
        },
        mounted() {
            this.init()
        }
    }
// eslint-disable-next-line
    ;
</script>
<style lang="scss">
    @import "~@/_variables.scss";

    .rtl {
        direction: rtl;
    }

    .vdp-datepicker {
        text-align: left;

        * {
            box-sizing: border-box;
        }

        &__calendar {
            z-index: 100;
            background: white;
            width: 100%;
            border: 1px solid #ccc;

            header {
                display: block;
                line-height: 40px;

                span {
                    display: inline-block;
                    text-align: center;
                    width: percentage((100-(100/7)*2) / 100);
                    float: left;
                }

                .prev,
                .next {
                    width: percentage((100 / 7) / 100);
                    float: left;
                    text-indent: -10000px;
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        border: 6px solid transparent;
                    }
                }

                .prev {
                    &:after {
                        border-right: 10px solid #000;
                        margin-left: -5px;
                    }

                    &.disabled:after {
                        border-right: 10px solid #ddd;
                    }
                }

                .next {
                    &:after {
                        border-left: 10px solid #000;
                        margin-left: 5px;
                    }

                    &.disabled:after {
                        border-left: 10px solid #ddd;
                    }
                }

                .prev:not(.disabled),
                .next:not(.disabled),
                .up:not(.disabled) {
                    cursor: pointer;
                }

                &:hover {
                    background: #eee;
                }
            }

            .disabled {
                color: #ddd;
                cursor: default;
            }

            .flex-rtl {
                display: flex;
                width: inherit;
                flex-wrap: wrap;
            }

            .cell {
                display: inline-block;
                padding: 0 5px;
                width: percentage((100/7) / 100);
                height: 40px;
                line-height: 40px;
                text-align: center;
                vertical-align: middle;
                border: 1px solid transparent;

                &:not(.blank):not(.disabled).day,
                &:not(.blank):not(.disabled).month,
                &:not(.blank):not(.disabled).year {
                    cursor: pointer;

                    &:hover {
                        border: 1px solid $cdpink;
                    }
                }

                &.selected {
                    background: $cdpink;

                    &:hover {
                        background: $visne;
                    }

                    &.highlighted {
                        background: $cdpink;
                    }
                }

                &.highlighted {

                    position: relative;

                    &:after {
                        position: absolute;
                        font-family: $font-family-fontawesome;
                        font-weight: 900;
                        font-size: $font-size-huge;
                        color: $cdpink;
                        top: 0;
                        right: 0;
                        left: 0;
                        content: "\f004";
                        z-index: -1;
                    }

                    &.disabled {
                        color: #a3a3a3;
                    }
                }

                &.grey {
                    color: #888;

                    &:hover {
                        background: inherit;
                    }
                }

                &.day-header {
                    font-size: 75%;
                    white-space: nowrap;
                    cursor: inherit;

                    &:hover {
                        background: inherit;
                    }

                }

                .month,
                .year {
                    width: 33.333%
                }
            }

        }

        &__clear-button,
        &__calendar-button {
            cursor: pointer;
            font-style: normal;

            &.disabled {
                color: #999;
                cursor: default;
            }
        }
    }

    .datepicker {

        &__calendar {

            &__header {
                display: flex;
                flex-flow: row nowrap;
                flex: 1 1 auto;
                justify-content: space-between;
                &__item {
                    box-sizing: border-box;
                    background-color: $cdpink;
                    border-radius: $border-radius;
                    font-size: $font-size-small;
                    align-items: center;
                    color: white;
                    cursor: pointer;

                    &:hover {
                        background-color: $visne;
                    }
                }
            }
        }
    }
</style>
