<template>
    <div>
        <div
            class="order-payment__transfer"
            v-for="(bank, index) in transferBanks"
            v-bind:key="index"
        >
            <label>
                <input :id="bank.value" :value="bank.value" v-model="transferBank" type="radio"/>
                {{ bank.name }}
            </label>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                transferBank: null,
                transferBanks: [
                    {
                        name: "Garanti Bankası",
                        value: "garanti",
                        information: ""
                    },
                    {
                        name: "Türkiye İş Bankası",
                        value: "isbankasi",
                        information: ""
                    },
                    {
                        name: "Finansbank",
                        value: "finansbank",
                        information: ""
                    }
                ]
            }
        }
    }
</script>
