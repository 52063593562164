<template>
    <CardInformation />
</template>
<script>
import CardInformation from "./CardInformation";

export default {
    name: "Bankakarti",
    components: {
        CardInformation
    }
}
</script>

