function checkSiparis(secilmisSiparis, state) {
    return secilmisSiparis && state.siparisler.hasOwnProperty(secilmisSiparis)
}


const getters = {
    alici(state) {
        const secilmisSiparis = state.secilmisSiparis;
        if (checkSiparis(secilmisSiparis, state)) {
            return state.siparisler[secilmisSiparis].alici;
        }
        return null;
    },
    gonderen(state) {
        const secilmisSiparis = state.secilmisSiparis;
        if (checkSiparis(secilmisSiparis, state)) {
            return state.siparisler[secilmisSiparis].gonderen;
        }
        return null;
    },
    kart(state) {
        const secilmisSiparis = state.secilmisSiparis;
        if (checkSiparis(secilmisSiparis, state)) {
            return state.siparisler[secilmisSiparis].kart;
        }
        return null;
    },
    urun(state) {
        const secilmisSiparis = state.secilmisSiparis;
        if (checkSiparis(secilmisSiparis, state)) {
            return state.siparisler[secilmisSiparis].urun;
        }
        return null;
    },
    siparis(state) {
        const secilmisSiparis = state.secilmisSiparis;
        if (checkSiparis(secilmisSiparis, state)) {
            return state.siparisler[secilmisSiparis];
        }
    },
    secilmisSiparis(state) {
        return state.secilmisSiparis;
    },
    siparisler(state) {
        return state.siparisler;
    },
    extraCount: (state) => (siparisId, extra) => {
        if (siparisId in state.siparisler) {
            const extraRecord = state.siparisler[siparisId].ekstralar.find(ext => ext.id === extra.id);

            if (extraRecord) {
                return extraRecord.adet;
            }
        }
        return 0;
    },
    faturavarmi(state) {
        return state.faturavarmi;
    },
    smsvarmi(state) {
        return state.smsvarmi;
    },
    bizemesaj(state) {
        return state.bizemesaj;
    },
    sepetId(state) {
        return state.id;
    },
    ayniadresikullan(state) {
        return state.ayniadresikullan;
    },
    odemetipi(state) {
        return state.odemetipi;
    }
};

export default getters;
