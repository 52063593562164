<template>
    <div class="container">
        <div class="order-progress">
            <div class="order-progress__bar-wrapper">
                <div class="order-progress__bar order-progress__bar--passive"></div>
                <div
                    class="order-progress__bar"
                    :class="assignProgressbarClass()"
                ></div>
            </div>
            <ul class="order-progress__list">
                <li class="order-progress__item"
                    :class="assignOrderProgressClass(0)"
                    @click="handleClick(0)"
                >
                    Ek Ürün Seçimi
                </li>
                <li class="order-progress__item"
                    :class="assignOrderProgressClass(1)"
                    @click="handleClick(1)"
                >
                    Sipariş Bilgileri
                </li>
                <li class="order-progress__item"
                    :class="assignOrderProgressClass(2)"
                    @click="handleClick(2)"
                >
                    Ödeme Bilgileri
                </li>
            </ul>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        extraPath: String,
        infoPath: String,
        paymentPath: String,
        currentPage: Number,
    },
    methods: {
        assignOrderProgressClass(progressPageNum) {
            if (progressPageNum <= this.currentPage) {
                return 'order-progress__item--selected'
            }
            return ""
        },
        assignProgressbarClass() {
            if (this.currentPage === 1) {
                return "order-progress__bar--active-half"
            } else if (this.currentPage === 2) {
                return "order-progress__bar--active-full"
            }

            return ""
        },
        handleClick(pageNumber) {
            let path = "/"
            if (pageNumber === 0) {
                path = this.extraPath;
            } else if (pageNumber === 1) {
                path = this.infoPath;
            } else if (pageNumber === 2) {
                path = this.paymentPath;
            }
            window.location.replace(path);
        }
    },
}
</script>
