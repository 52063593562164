<template>
    <div class="extra-products">
        <extra-item
            v-for="(extra, index) in extraProducts"
            :key="index"
            :item="extra"
        />
    </div>
</template>

<script>
import {fetchExtraProducts} from "../../../api/extra-products";
import ExtraItem from "./ExtraItem";

export default {
    name: "index.vue",
    data() {
        return {
            extraProducts: null,
        }
    },
    created() {
        fetchExtraProducts().then((extraProducts) => this.extraProducts = extraProducts);
    },
    components: {
        ExtraItem
    }
}
</script>
