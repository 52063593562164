<template>

    <div class="order-information">
        <div class="row">
            <div class="col-lg-6">
                <input
                    id="useAddressAll"
                    name="useAddressAll"
                    type="checkbox"
                    v-model="useAddressAll"
                >
                <label for="useAddressAll">
                    Tüm ürünler için aynı adresi kullanın
                </label>
            </div>
        </div>

        <order-form :action="''"></order-form>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import OrderForm from "./OrderForm/index"

export default {
    name: "index",
    components: {
        OrderForm,
    },
    computed: {
        ...mapGetters('sepet', {
            selectedOrder: 'secilmisSiparis',
        }),
        ...mapGetters('sepet', [
            'ayniadresikullan',
        ]),
        useAddressAll: {
            get() {
                return this.ayniadresikullan
            },
            set(newValue) {
                if (!(newValue && this.ayniadresikullan)) {
                    this.toggleCommonAddress()
                }
            }
        }
    },
    methods: {
        ...mapActions('sepet', [
            'toggleCommonAddress',
        ]),
        placeOrder: () => {
        },
        updateOrder: () => {
        },
    }
}
</script>
