<template>
    <div class="info-form">
        <ValidationObserver v-slot="{ validate }">
            <form :action="action" @submit="(event) => onSubmit(event, validate)" ref="form">
                <gonderen/>

                <alici/>

                <kart/>

                <teslimat/>

                <fatura />

                <submit/>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import {ValidationObserver} from "vee-validate";
import {mapActions} from "vuex";

import Gonderen from "./Gonderen";
import Alici from "./Alici";
import Kart from "./Kart";
import Teslimat from "./Teslimat";
import Fatura from "./Fatura";
import Submit from "./Submit";

export default {
    components: {
        Gonderen,
        Alici,
        Teslimat,
        Kart,
        Submit,
        ValidationObserver,
        Fatura
    },

    props: {
        action: String
    },

    methods: {
        ...mapActions('sepet', [
            'submitCart',
            'toggleReceipt'
        ]),
        onSubmit(event, validate) {
            event.preventDefault();
            validate().then(validateResult => {
                if(validateResult) {
                    this.submitCart().then(res => {
                        if(res.value) {
                            window.location.href = '/siparis/odeme'
                        }
                    })
                }
            })
        }
    },


};
</script>
