<template>
    <form-input-layout :id="id" :label="label" :rules="rules">
        <template v-slot:input>
      <textarea
          :id="id"
          :name="name"
          placeholder
          :rows="rows"
          :value="value"
          @input="$emit('input', $event.target.value)"
      />
        </template>
    </form-input-layout>
</template>
<script>
import FormInputLayout from "./FormInputLayout";

export default {
    props: {
        id: String,
        name: String,
        label: String,
        value: String,
        rows: {
            type: Number,
            default: 4
        },
        rules: String
    },
    components: {
        FormInputLayout
    }
};
</script>
