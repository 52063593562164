<template>
    <div v-scroll="handleScroll">
        <transition name="fade">
            <div class="sticky-header" v-if="show">
                <div class="container">
                    <div class="row">
                        <div class="logo desktop">
                            <a href="#">
                                <img class="img-fluid" :src="logo">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                show: false
            }
        },
        props: ['logo'],
        methods: {
            handleScroll(evt, el) {
                if (window.scrollY > 50) {
                    this.show = true
                } else {
                    this.show = false
                }
            }
        }
    }
</script>
