export const SET_ALICI = "SET_ALICI";
export const SET_GONDEREN = "SET_GONDEREN";
export const SET_KART = "SET_KART";
export const SET_URUN = "SET_URUN";
export const CREATE_SIPARIS = "CREATE_SIPARIS";
export const SET_SECILMIS_SIPARIS = "SET_SECILMIS_SIPARIS";
export const DELETE_SIPARIS = "DELETE_SIPARIS";
export const ADD_EXTRA_ITEM = "ADD_EXTRA_ITEM";
export const DELETE_EXTRA_ITEM = "DELETE_EXTRA_ITEM";
export const EMPTY_SEPET = "EMPTY_SEPET";
export const TOGGLE_RECEIPT = "TOGGLE_RECEIPT";
export const SET_SMSVARMI = "SET_SMSVARMI";
export const SET_BIZEMESAJ = "SET_BIZEMESAJ";
export const SUBMIT_CART = "SUBMIT_CART";
export const UPDATE_CART = "UPDATE_CART";
export const TOGGLE_COMMON_ADDRESS = "TOGGLE_COMMON_ADDRESS";
export const SET_ODEMETIPI = "SET_ODEMETIPI";
