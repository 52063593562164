<template>
    <div class="cart">
        <div class="cart__tab">
            <i class="icon-cart"></i>
            <span>SEPET ({{ Object.keys(products).length }})</span>
        </div>

        <div class="cart__box p-3">
            <ul class="cart__list">
                <li class="cart__item py-2" v-for="item in Object.values(products)">
                    <img class="cart__item__img" :src="'/buyukresim/' + item.urun.resim['buyukresim1']">
                    <div class="cart__item__info d-flex flex-column">
                        <span class="cart__item__name px-1">{{ item.urun.ad }}</span>
                        <span class="cart__item__price px-1">{{(item.urun.fiyat).toFixed(2) }} ₺</span>
                    </div>
                    <i class="cart__item__remove fas fa-trash-alt px-1" @click="deleteSiparis(item.sepetici_id)"></i>
                </li>
            </ul>

            <div class="cart__total py-3">
                <span><b>Tutar</b></span>
                <span class="cart__total__price">{{ totalPrice.toFixed(2) }} ₺ + KDV</span>
            </div>

            <button class="btn btn--bg-visne btn--w-100 btn--color-white my-3" @click="empty">
                Sepeti Boşalt
            </button>

            <a class="btn btn--bg-visne btn--w-100 btn--color-white my-0"
                href="/sepetim"
            >
                Sepete Git
            </a>
        </div>
    </div>
</template>
<script>
    import {mapGetters, mapActions} from 'vuex';
    import { calculateTotalPrice } from "./utils";

    export default {
        data() {
            return {
                show: false,
            }
        },
        methods: {
            onMouseover() {
                this.show = true
            },
            onMouseleave() {
                this.show = false
            },
            ...mapActions('sepet', [
                'deleteSiparis',
                'emptyCart'
            ]),
            empty(){
                this.emptyCart()
            },
            calculateTotalPrice
        },
        computed: {
            ...mapGetters('sepet', {
                products: 'siparisler'
            }),

            totalPrice() {
                return this.calculateTotalPrice(this.products) || 0;
            }
        },
    }
</script>
<style lang="scss" scoped>
    @import "~@/_variables.scss";

    .cart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1;

        &__box {
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            right: 0;
            width: 75%;
            background-color: white;
            visibility: hidden;
            border: .1rem solid $visne;
            transform: translateY(10%);
            opacity: 0;
            transition: opacity .5s, transform .5s ease;
            z-index: 1;
        }

        &:hover &__box, &:active &__box {
            visibility: visible;
            transform: translateY(0);
            opacity: 1;
        }

        &__tab {
            display: flex;
            align-items: center;
        }

        &__item {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            box-sizing: border-box;
            font-size: $font-size-base;
            justify-content: space-evenly;

            &__info {
                width: 65%;
            }

            &__img {
                width: 30%;
            }


            &__remove {
                width: 5%;
                cursor: pointer;
            }
        }

        &__total {
            display: flex;
            flex-flow: row nowrap;
            color: dimgrey;
            font-size: $font-size-big;
            justify-content: space-between;
            border-top: .1rem solid lightgray;
            border-bottom: .1rem solid lightgray;

            &__price {
                color: $visne;
            }
        }
    }

    @media (min-width: $breaking-point) {
        .cart {
            &__box {
                width: 25%;
            }
        }
    }
</style>
