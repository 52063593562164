import * as types from "./types";
import Vue from "vue";

const mutations = {
    [types.SET_ALICI](state, { alici }) {
        if (state.secilmisSiparis) {
            Vue.set(state.siparisler[state.secilmisSiparis], "alici", alici);
        }
    },
    [types.SET_GONDEREN](state, { gonderen }) {
        if (state.secilmisSiparis) {
            Vue.set(state.siparisler[state.secilmisSiparis], "gonderen", gonderen);
        }
    },
    [types.SET_KART](state, { kart }) {
        if (state.secilmisSiparis){
            Vue.set(state.siparisler[state.secilmisSiparis], "kart", kart);
        }
    },
    [types.SET_URUN](state, { urun }) {
        if (state.secilmisSiparis) {
            Vue.set(state.siparisler[state.secilmisSiparis], "urun", urun);
        }
    },
    [types.CREATE_SIPARIS](state, {siparis}) {
        Vue.set(state.siparisler, siparis.sepetici_id, siparis);
    },
    [types.SET_SECILMIS_SIPARIS](state, {siparisId}) {
        Vue.set(state, "secilmisSiparis", siparisId);
    },
    [types.DELETE_SIPARIS](state, {siparisId}) {
        Vue.delete(state.siparisler, siparisId);
        if (state.secilmisSiparis === siparisId) {
            state.secilmisSiparis = null;
        }
    },
    [types.ADD_EXTRA_ITEM](state, {siparisId, extraItem}) {
        const prevExtra = state.siparisler[siparisId].ekstralar.find(item => item.id === extraItem.id);
        if (!prevExtra) {
            state.siparisler[siparisId].ekstralar.push({...extraItem, adet: 1});
        } else if(prevExtra.adet < 5) {
            const others = state.siparisler[siparisId].ekstralar.filter(extra => extra.id !== prevExtra.id);
            const prevCount = prevExtra.adet;
            const newEkstralar = [...others, {...prevExtra, adet: prevCount + 1}];
            Vue.set(state.siparisler[siparisId], "ekstralar", newEkstralar);
        }
    },
    [types.DELETE_EXTRA_ITEM](state, {siparisId, extraItem}) {
        const prevExtra = state.siparisler[siparisId].ekstralar.find(item => item.id === extraItem.id);
        if(prevExtra) {
            const others = state.siparisler[siparisId].ekstralar.filter(extra => extra.id !== prevExtra.id);
            if (prevExtra.adet === 1) {
                Vue.set(state.siparisler[siparisId], "ekstralar", others);
            } else {
                const prevCount = prevExtra.adet;
                const newEkstralar = [...others, {...prevExtra, adet: prevCount - 1}];
                Vue.set(state.siparisler[siparisId], "ekstralar", newEkstralar);
            }
        }
    },
    [types.EMPTY_SEPET](state) {
        Vue.set(state, "siparisler", {});
    },
    [types.TOGGLE_RECEIPT](state) {
        state.faturavarmi = !state.faturavarmi;
    },
    [types.SET_BIZEMESAJ](state, {bizemesaj}) {
        state.bizemesaj = bizemesaj;
    },
    [types.SET_SMSVARMI](state, {smsvarmi}) {
        state.smsvarmi = smsvarmi;
    },
    [types.SUBMIT_CART](state, {sepet}) {
        state.id = sepet.id;
    },
    [types.UPDATE_CART](state) {

    },
    [types.TOGGLE_COMMON_ADDRESS](state){
        state.ayniadresikullan = !state.ayniadresikullan;
    },
    [types.SET_ODEMETIPI](state, {odemetipi}) {
        state.odemetipi = odemetipi;
    }
};

export default mutations;
