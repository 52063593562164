<template>
    <transition name="summary-slide" v-if="this.showSummary">
        <div class="col-lg-3">
            <div class="order-summary">
                <cart-summary></cart-summary>
            </div>
        </div>
    </transition>
</template>

<script>
import CartSummary from "../cart/CartSummary";
import {mapGetters} from "vuex";

export default {
    name: "OrderSummary",
    components: {
        CartSummary
    },
    computed: {
        ...mapGetters('ui', [
            'showSummary'
        ])
    }
}
</script>

<style lang="scss" scoped>
.summary-slide-enter-active,
.summary-slide-leave-active {
    transition: opacity .5s;
}

.summary-slide-enter,
.summary-slide-leave-to {
    opacity: 0;
}
</style>

